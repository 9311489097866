import Headroom from 'headroom.js';

(function () {
  'use strict';

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);

  const header = document.querySelector('.main-header');
  const triggerMenu = document.querySelector('#trigger-menu');
  const body = document.body;

  function handleDOMContentLoaded() {
    triggerMenu.addEventListener('click', openCloseMenu);

    new Headroom(header, {
      offset: 80,
      classes: {
        unpinned: 'main-header--hide'
      }
    }).init();
  }

  function openCloseMenu(e) {
    e.preventDefault();

    if (body.classList.contains('has-navigation-open')) {
      body.classList.remove('has-navigation-open');
      e.currentTarget.classList.remove('is-active');
    } else {
      body.classList.add('has-navigation-open');
      e.currentTarget.classList.add('is-active');
    }
  }
})();
